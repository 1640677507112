import Adv from "@/components/main/adv";
import News from "@/components/main/news";
import Numbers from "@/components/main/numbers";
import Portfolio from "@/components/main/portfolio";
import ProductReviews from "@/components/main/productReviews";
import Reviews from "@/components/main/reviews";
import Sections from "@/components/main/sections";
import Selector from "@/components/main/selector";
import Shops from "@/components/main/shops";
import Map from "~/components/main/map";
import Video from "~/components/main/video";
import Videos from "~/components/main/videos";

import styles from "./index.module.scss";

export default function FarkopHome({ host }: any) {
  return (
    <>
      <Video />
      <div className="container">
        <div className={styles.Top}>
          <div className={styles.Selector}>
            <Selector />
          </div>
          <Sections />
        </div>
      </div>
      <Numbers />
      <div className="container">
        <div className={styles.Bottom}>
          <Shops
            rows={3}
            host={host}
            isBaltex={false}
            isShowShopsForRegions={false}
          />
          <Adv />
          <div className={styles.News}>
            <News hideDate />
            <ProductReviews />
          </div>
          <Portfolio />
          <Reviews />
          <Videos />
          {(host === "msk.farkop.ru" ||
            host === "spb.farkop.ru" ||
            host === "localhost:3000") && <Map />}
        </div>
      </div>
    </>
  );
}
