import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import clsx from "clsx";
import Link from "next/link";
import styles from "./index.module.scss";
import Maper from "../../pages/contacts/maper";

export default function Map({ isShowTitle = true }) {
  const { shops } = useSelector((state) => state.location);
  const { cityName } = useSelector((state) => state.location);
  const { contactsData, contacts } = useSelector((state) => state.contacts);
  const [pointId, setPointId] = useState(null);
  let currentShops = [];
  if (contactsData?.SHOPS) {
    currentShops = contactsData?.SHOPS.map((id) => shops[id]).filter(
      (shop) => shop,
    );
  }
  let points = currentShops;
  if (cityName === "Россия" && contacts.length > 0) {
    points = [...contacts[0].SHOPS, ...contacts[1].SHOPS]
      .map((id) => shops[id])
      .filter((shop) => shop);
  }
  const isSdek = cityName !== "Россия" && contactsData.ID === "86897";
  // eslint-disable-next-line no-nested-ternary
  const zoom = isSdek ? 10 : points.length > 2 ? 5 : 9;
  const sectionRef = useRef(null);
  return (
    <div className={styles.Block}>
      <div className={styles.Head}>
        {isShowTitle && <h2>Контакты</h2>}

        <div className={styles.Info}>
          <div>
            <div className={styles.InfoLabel}>Телефон:</div>
            <a
              href={`tel:${contactsData?.PHONE}`}
              className={contactsData?.CALL_TOUCH_CODE}
            >
              {contactsData?.PHONE}
            </a>
          </div>
          <div>
            <div className={styles.InfoLabel}>E-mail:</div>
            <a href={`mailto:${contactsData?.EMAIL}`}>{contactsData?.EMAIL}</a>
          </div>
        </div>
      </div>
      <div className={styles.Body}>
        <div className={styles.Adr}>
          {currentShops.map((shop, i) => (
            <div
              key={i}
              className={clsx(
                styles.AdrBlock,
                shop.ID === pointId && styles.AdrBlockSelected,
              )}
              onClick={() => {
                if (window.outerWidth < 991) {
                  sectionRef.current?.scrollIntoView({ behavior: "smooth" });
                  setPointId(shop.ID);
                }
              }}
            >
              {shop.DRIVE_SCHEME && (
                <div className={styles.Scheme}>Схема проезда</div>
              )}
              <div>
                <div className={styles.AdrBlockLabel}>Адрес:</div>
                <span dangerouslySetInnerHTML={{ __html: shop["~ADR"] }} />
              </div>
              {shop.WORK_TIME && (
                <div>
                  <div className={styles.AdrBlockLabel}>Режим работы:</div>
                  <span>{shop?.WORK_TIME}</span>
                </div>
              )}
            </div>
          ))}
          <div className={styles.AdrBottom}>
            <div className={styles.AdrBottomNews}>
              <div>Читайте нас на:</div>
              <div className={styles.Read}>
                <a
                  className={styles.Drive2}
                  href="https://www.drive2.ru/users/farkopru/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/drive2.svg" />
                </a>
                <a
                  className={styles.Drive2}
                  href="https://vk.com/farkopru"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/vk.svg" />
                </a>
              </div>
            </div>
            <div className={styles.AdrBottomSoc}>
              <div>Социальные сети:</div>
              <div className={styles.SocList}>
                <Link href="https://www.instagram.com/farkop.ru/">
                  <a>
                    <img src="/soc/2.svg"></img>
                  </a>
                </Link>
                <Link href="https://vk.com/farkopru">
                  <a>
                    <img src="/soc/3.svg"></img>
                  </a>
                </Link>
                <Link href="https://www.facebook.com/%D0%A4%D0%B0%D1%80%D0%BA%D0%BE%D0%BF-%D0%A1%D0%9F%D0%B1-451260541645692/">
                  <a>
                    <img src="/soc/1.svg"></img>
                  </a>
                </Link>
                <Link href="https://www.youtube.com/channel/UCvIC3w49aEkOLBH4gG4iCbw">
                  <a>
                    <img src="/soc/4.svg"></img>
                  </a>
                </Link>
              </div>
            </div>
            <span className={styles.Meta}>
              *Деятельность Meta (соцсети Facebook и Instagram) запрещена в
              России как экстремистская организация.
            </span>
          </div>
        </div>

        <div className={styles.Map} ref={sectionRef}>
          <Maper zoom={zoom} points={points} pointId={pointId} />
        </div>
      </div>
    </div>
  );
}
