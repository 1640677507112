import { useSelector } from "react-redux";
import Link from "next/link";
import styles from "./index.module.scss";

export default function Portfolio() {
  const { mainPortfolio } = useSelector((state) => state.content);

  return mainPortfolio.length === 0 ? null : (
    <div className={styles.News}>
      <h2>
        Портфолио
        <Link href={"/nashe-portfolio/"}>Все работы</Link>
      </h2>
      <div className={styles.NewsList}>
        {mainPortfolio?.map((item) => (
          <Link key={item.ID} href={item.DETAIL_PAGE_URL}>
            <a>
              <div className={styles.Picture}>
                <img src={item.PICTURE_SRC} />
              </div>
              <div className={styles.Info}>
                <div className={styles.Name}>{item.NAME}</div>
                <span className={styles.Bottom}>
                  {item.FORMATTED_DATE} · {item.SECTION.NAME}
                </span>
              </div>
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
}
