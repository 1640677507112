import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import clsx from "clsx";
import styles from "./index.module.scss";
import Button from "../../common/button";
import { showPopup } from "../../../redux/action-creaters/popup";
import { popupTypes } from "../../../redux/reducers/popupReducer";

export default function Faq() {
  const { questions } = useSelector((state) => state.content);
  const dispatch = useDispatch();

  const [openedQuestions, toggleQuestions] = useState({});

  const filteredQuestions = useMemo(() => {
    return questions;
  }, [questions]);

  return (
    <div className={styles.Questions}>
      <div className={styles.Right}>
        <h2>Ответ на вопрос</h2>
        <span>Не нашли ответ на свой вопрос?</span>
        <Button
          label="Задать вопрос"
          onClick={() => {
            dispatch(showPopup(popupTypes.message));
          }}
        />
      </div>

      <ul className={styles.Question}>
        {filteredQuestions.map((question) => (
          <li
            key={question.ID}
            onClick={() =>
              toggleQuestions({
                ...openedQuestions,
                [question.ID]: !openedQuestions[question.ID],
              })
            }
          >
            <span className={styles.Name}>{question.NAME}</span>

            {openedQuestions[question.ID] && (
              <div
                className={styles.Text}
                dangerouslySetInnerHTML={{ __html: question.DETAIL_TEXT }}
              ></div>
            )}

            <span
              className={clsx(
                openedQuestions[question.ID] && styles.ArrowSelected,
                styles.Arrow,
              )}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.9998 15.7131L18.0098 9.70209L16.5958 8.28809L11.9958 12.8881L7.40277 8.28809L5.98877 9.70209L11.9998 15.7131Z" />
              </svg>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
