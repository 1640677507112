import { useSelector } from "react-redux";
import Link from "next/link";
import styles from "./index.module.scss";

export default function News({ hideDate = false }) {
  const { mainNews } = useSelector((state) => state.content);

  return (
    <div className={styles.News}>
      <h2>
        Статьи и новости
        <Link href={"/articles/"}>Все новости</Link>
      </h2>
      <div className={styles.NewsList}>
        {mainNews?.map((item) => (
          <Link key={item.ID} href={item.DETAIL_PAGE_URL}>
            <a>
              <div className={styles.Picture}>
                <img src={item.PICTURE_SRC} />
              </div>
              <div className={styles.Info}>
                <div className={styles.Name}>{item.NAME}</div>
                <span className={styles.Bottom}>
                  {!hideDate && `${item.FORMATTED_DATE} · `}
                  {item.SECTION.NAME}
                </span>
              </div>
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
}
