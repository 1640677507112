import Link from "next/link";
import styles from "./index.module.scss";

export default function News({ items, isShowTitle = true }) {
  return (
    <div className={styles.News}>
      {isShowTitle && (
        <h2>
          Полезная информация
          <Link href={"/important-info/"}>Вся информация</Link>
        </h2>
      )}
      <div className={styles.NewsList}>
        {items?.map((item, index) => (
          <div key={index}>
            <span className={styles.Bottom}>{item.FORMATTED_DATE}</span>
            <div className={styles.Name}>{item.NAME}</div>
            <div
              className={styles.Description}
              dangerouslySetInnerHTML={{ __html: item.PREVIEW_TEXT }}
            ></div>

            <a className={styles.Link} href={item.DETAIL_PAGE_URL}>
              Подробнее
              <span>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.5L5.5 6L1 10.5"
                    stroke="#0FB1B1"
                    strokeWidth="2"
                  />
                </svg>
              </span>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
