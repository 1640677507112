import { useDispatch } from "react-redux";

import BaltexAbout from "~/components/baltex/about";
import BaltexAdv from "~/components/baltex/adv";
import News from "~/components/baltex/news";
import BaltexNumbers from "~/components/baltex/numbers";
import Production from "~/components/baltex/production";
import BaltexSections from "~/components/baltex/sections";
import BaltexSlider from "~/components/baltex/slider";
import Selector from "~/components/main/selector";
import { showPopup } from "~/redux/action-creaters/popup";
import { popupTypes } from "~/redux/reducers/popupReducer";

import styles from "./index.module.scss";

export default function BaltexHome({ slider, adv, productions, news }: any) {
  const dispatch = useDispatch();
  return (
    <div className={styles.Baltex}>
      <BaltexSlider items={slider} />

      <div className={styles.BaltexModels}>
        <div className="container">
          <Selector isBaltex />
        </div>
      </div>
      <div className="container">
        <div className={styles.BaltexCatalog}>
          <BaltexSections />
        </div>
      </div>
      <div className={styles.BaltexNumbers}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={styles.Video}
          onClick={() => {
            dispatch(showPopup(popupTypes.video, "Bda-0c1Kmok"));
          }}
        >
          {/* <span>
                        <svg width="18" height="22" viewBox="0 0 18 22" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 11L-9.78799e-07 21.3923L-7.02746e-08 0.607695L18 11Z" fill="white"/>
                        </svg>
                    </span>
                    <div>Смотреть видео</div> */}
          <div className="container">
            <BaltexNumbers />
          </div>
        </div>
      </div>

      <div className="container">
        <BaltexAbout />
        <BaltexAdv items={adv} />
        <div className={styles.Sert}>
          <span>Получить сертификат на фаркоп BALTEX</span>
          <a
            href="/upload/iblock/10e/10e2ef1966a60d14b9ec8011e5af0c16.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Скачать сертификат
            <span>
              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 3L5 7L9 3" stroke="white" strokeWidth="2" />
                <path d="M5 0V6.5" stroke="white" strokeWidth="2" />
                <rect y="11" width="10" height="2" fill="white" />
              </svg>
            </span>
          </a>
        </div>
      </div>

      <div className="container">
        <div className={styles.BaltexBloks}>
          <Production items={productions} />
          <News items={news} />
        </div>
      </div>
    </div>
  );
}
