import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.scss";
import { showPopup } from "../../../redux/action-creaters/popup";
import { popupTypes } from "../../../redux/reducers/popupReducer";

export default function Video() {
  const { mainSlider } = useSelector((state) => state.content);
  const videoRef = useRef();
  const slider = mainSlider[0];
  const dispatch = useDispatch();
  return (
    <div className={styles.Video}>
      <div className={"container"}>
        <div className={styles.Content}>
          <h1>{slider.NAME}</h1>
          <div
            className={styles.Title}
            dangerouslySetInnerHTML={{ __html: slider.PREVIEW_TEXT }}
          ></div>
          <div
            className={styles.Full}
            onClick={() => {
              dispatch(
                showPopup(
                  popupTypes.videoFull,
                  "https://rutube.ru/play/embed/b511f3f5ac1b78ed7cad46d6611e7bb4?skinColor=0e8dee",
                ),
              );
            }}
          >
            Смотреть полное видео
          </div>
        </div>
      </div>
      <video ref={videoRef} autoPlay muted loop>
        <source src={"/site.mp4"} type="video/mp4" />
      </video>
    </div>
  );
}
