import LandingsAbout from "~/components/landings/about";
import LandingsAdv from "~/components/landings/adv";
import LandingsContacts from "~/components/landings/contacts";
import Faq from "~/components/landings/faq";
import LandingsModels from "~/components/landings/models";
import LandingsNumbers from "~/components/landings/numbers";
import LandingsReviews from "~/components/landings/reviews";
import LandingsSlider from "~/components/landings/slider";
import Videos from "~/components/landings/videos";

import styles from "./index.module.scss";

export default function LandingsHome({ slider, models, adv }: any) {
  return (
    <div className={styles.Landings}>
      <LandingsSlider items={slider} />
      <div className="container">
        <div className={styles.LandingsModels}>
          <LandingsModels items={models} />
        </div>
      </div>
      <div className={styles.LandingsGray}>
        <div className="container">
          <div className={styles.LandingsBloks}>
            <LandingsAdv items={adv} />
            <LandingsAbout />
            <LandingsNumbers />
          </div>
        </div>
      </div>
      <div className="container">
        <div className={styles.LandingsBloks}>
          <LandingsReviews />
          <Videos />
          <LandingsContacts />
        </div>
      </div>

      <div className={styles.LandingsGray}>
        <div className="container">
          <div className={styles.LandingsBloks}>
            <Faq />
          </div>
        </div>
      </div>
    </div>
  );
}
