import { useSelector } from "react-redux";
import styles from "./index.module.scss";

export default function LandingsNumbers() {
  const { mainNumbers } = useSelector((state) => state.content);

  return (
    <div className={styles.Numbers}>
      <div className={styles.Items}>
        {mainNumbers.map((number) => (
          <div key={number.ID}>
            <div className={styles.Value}>
              <div>более</div>
              <span className={styles.Num}>
                {number["~PROPERTY_NUM_VALUE"]}
              </span>
              <span>{number.PROPERTY_MAGNITUDE_VALUE}</span>
            </div>

            <span className={styles.Name}>{number.NAME}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
