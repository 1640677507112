import styles from "./index.module.scss";

export default function BaltexAdv({ items }) {
  return (
    <div className={styles.Adv}>
      <div className={styles.Items}>
        {items?.map((adv) => (
          <div key={adv.ID} className={styles.Item}>
            <span className={styles.Image}>
              <img src={adv.PICTURE_SRC} />
            </span>
            <span className={styles.Name}>{adv.NAME}</span>
            <span
              className={styles.Text}
              dangerouslySetInnerHTML={{ __html: adv.PREVIEW_TEXT }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
