import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import Maper from "/components/pages/contacts/maper";
import clsx from "clsx";
import styles from "./index.module.scss";

export default function LandingsContacts() {
  const [city, setCity] = useState("Москва");
  const { shops } = useSelector((state) => state.location);
  const { contacts } = useSelector((state) => state.contacts);
  const [pointId, setPointId] = useState(null);

  const contact = useMemo(() => {
    return contacts.find((item) => item.NAME === city);
  }, [city, contacts]);

  const contactShops = useMemo(() => {
    return contact?.SHOPS?.map((shopId) => shops[shopId]) || [];
  }, [contact, shops]);

  return (
    <div className={styles.Contacts}>
      <div className={styles.ContactsRight}>
        <h2 className={styles.Top}>Наши магазины</h2>
        <div className={styles.Adreses}>
          {contactShops.map((shop, i) => (
            <div key={i} className={styles.AdrBlock}>
              <span dangerouslySetInnerHTML={{ __html: shop["~ADR"] }} />
              {shop.WORK_TIME && (
                <div>
                  <div className={styles.Time}>Режим работы:</div>
                  <span>{shop?.WORK_TIME}</span>
                </div>
              )}

              {shop.DRIVE_SCHEME && (
                <div
                  className={clsx(
                    styles.Scheme,
                    shop.ID === pointId && styles.SchemeSelected,
                  )}
                  onClick={() => setPointId(shop.ID)}
                >
                  Схема проезда
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.Map}>
        <div className={clsx(styles.Selector, styles.Top)}>
          <span
            className={clsx(city === "Москва" && styles.Selected)}
            onClick={() => {
              setCity("Москва");
            }}
          >
            Москва
          </span>
          <span
            className={clsx(city === "Санкт-Петербург" && styles.Selected)}
            onClick={() => {
              setCity("Санкт-Петербург");
            }}
          >
            Санкт-Петербург
          </span>
        </div>
        <Maper zoom={10} points={contactShops} pointId={pointId} />
      </div>
    </div>
  );
}
