import { useSelector, useDispatch } from "react-redux";
import { showPopup } from "/redux/action-creaters/popup";
import { popupTypes } from "/redux/reducers/popupReducer";
import styles from "./index.module.scss";

export default function Videos() {
  const dispatch = useDispatch();
  const { videos } = useSelector((state) => state.content);

  return (
    <div>
      <div className={styles.Videos}>
        {videos.map((item) => (
          <div
            className={styles.Item}
            key={item.ID}
            onClick={() => {
              dispatch(
                showPopup(popupTypes.video, item.PROPERTY_VIDEO_CODE_VALUE),
              );
            }}
          >
            <img
              src={`//img.youtube.com/vi/${item.PROPERTY_VIDEO_CODE_VALUE}/hqdefault.jpg`}
            />

            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="32" cy="32" r="32" fill="#FADE74" />
              <path
                d="M37.2929 32.7071C37.6834 32.3166 37.6834 31.6834 37.2929 31.2929L31.7071 25.7071C31.0771 25.0771 30 25.5233 30 26.4142V37.5858C30 38.4767 31.0771 38.9229 31.7071 38.2929L37.2929 32.7071Z"
                fill="#1D2A34"
              />
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
}
