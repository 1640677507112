import styles from "./Slider.module.scss";

export default function LandingsModels({ items }) {
  return (
    <div className={styles.Models}>
      <h2>
        Модельный ряд <span>{items.length} Моделей</span>
      </h2>

      <div className={styles.Items}>
        {items?.length > 0 &&
          items.map((slider) => (
            <a
              key={slider.ID}
              className={styles.Item}
              href={slider.PROPERTY_LINK_VALUE || "/"}
              style={{ "background-image": `url(${slider.PICTURE_SRC})` }}
            >
              {slider.NAME}
            </a>
          ))}
      </div>
    </div>
  );
}
