import styles from "./index.module.scss";

export default function BaltexAbout() {
  return (
    <div className={styles.Main}>
      <div className={styles.MainInfo}>
        <h1>Компания ООО “Балтекс”</h1>
        <div>
          Компания BALTEX предлагает также фаркопы премиум-класса. Особенность
          ряда моделей состоит в том, что в их комплект входят декоративные
          накладки из нержавеющей стали. Отполированные до зеркального блеска,
          такие накладки придают внешнему виду фаркопа неповторимость и
          чрезвычайную изысканность стиля. А союз автомобиля с прицепом в этом
          случае выглядит воистину «блестящим».
          {/* <div dangerouslySetInnerHTML={{ __html: content }}/> */}
        </div>
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a href="/about/">Подробнее о компании</a>
      </div>
      <div className={styles.MainLogo}>
        <img src="/baltex/baltexAbout.png"></img>
      </div>
    </div>
  );
}
