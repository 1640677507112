import Link from "next/link";
import styles from "./index.module.scss";

export default function Production({ items, isShowTitle = true }) {
  return (
    <div className={styles.News}>
      {isShowTitle && (
        <h2>
          Новости производства
          <Link href={"/production/"}>Все новости производства</Link>
        </h2>
      )}
      <div className={styles.NewsList}>
        {Array.isArray(items) &&
          items?.map((item) => (
            <Link key={item.ID} href={item.DETAIL_PAGE_URL}>
              <a style={{ backgroundImage: `url(${item.PICTURE_SRC})` }}>
                <div className={styles.Info}>
                  <div className={styles.Name}>{item.NAME}</div>
                </div>
              </a>
            </Link>
          ))}
      </div>
    </div>
  );
}
