import { useSelector } from "react-redux";
import Link from "next/link";
import styles from "./index.module.scss";

export default function Numbers() {
  const { mainNumbers } = useSelector((state) => state.content);
  const { content } = useSelector((state) => state.meta);

  return (
    <div className={styles.Numbers}>
      <div className="container">
        <div className={styles.Items}>
          {mainNumbers.map((number) => (
            <div key={number.ID}>
              <div className={styles.Value}>
                <div>более</div>
                <span className={styles.Num}>
                  {number["~PROPERTY_NUM_VALUE"]}
                </span>
                <span>{number.PROPERTY_MAGNITUDE_VALUE}</span>
              </div>

              <span className={styles.Name}>{number.NAME}</span>
            </div>
          ))}
        </div>
        <div className={styles.Text}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <Link href="/about/">Подробнее</Link>
        </div>
      </div>
    </div>
  );
}
